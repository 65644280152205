.button-team-game {
    width: 100px;
    height: 30px;
}
.team-game-button {
    width: 60px;
    height: 30px;
    background: #1c9386;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 5px;
}
